import React, { useState,useMemo } from 'react';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';
import HeaderBack from '../components/header-back';
import Layout from '../components/layout';
import CategoryInfo from '../components/category-info';

const Books = ({ data }) => {
  const [filter, setFilter] = useState('');
  const filteredData = useMemo(
    () => {
      return (data.allGbvBooksCatalogCsv.nodes[0].items || [])
        .slice(1)
        .filter((row) =>
          Object.values(row)
            .join(' ')
            .toLowerCase()
            .includes(filter.toLowerCase()),
        );
    },
    [filter, data],
  );

  return (
    <Layout>
      <Helmet>
        <title>Литература издательство GBV (Gute Botschaft Verlag)</title>
        <meta
          name="description"
          content="Литература Gute Botschaft Verlag, календари Доброе семя, Господь близко"
        />
        <meta
          name="keywords"
          content="Литература GBV, Gute Botschaft Verlag, календари Доброе семя, Господь близко"
        />
      </Helmet>
      <HeaderBack small variant="simple">
        <h1 className="page-title">Литература GBV</h1>
      </HeaderBack>
      <div className="content">
        <div className="container">
          <CategoryInfo>
            <p>
              Здесь представлен список литературы, изданной издательством{' '}
              <a
                href="https://gbv-dillenburg.de/en"
                rel="noreferrer"
                target="_blank">
                GBV Dillenburg
              </a>{' '}
              на русском языке. Некоторые книги вы можете найти в электронном
              виде на сайте{' '}
              <a
                href="https://gbv-online.org/"
                rel="noreferrer"
                target="_blank">
                GBV Online
              </a>
              .
            </p>
          </CategoryInfo>
          <div className="row">
            <div className="col-md-6 col-md-push-3">
              <div className="widget">
                <div className="search">
                  <input
                    type="text"
                    value={filter}
                    className="search-input form-control"
                    placeholder="Поиск по книгам"
                    onChange={(e) => setFilter(e.target.value)}
                  />
                  <i className="fa fa-search search-icon"></i>
                </div>
              </div>
            </div>
          </div>

          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Артикул</th>
                  <th>Название</th>
                  <th>Автор</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.map((row) => (
                  <tr key={row.Code}>
                    <td>{row.Code}</td>
                    <th>
                      {row.Title} {!!row.Volume && `($${row.Volume})`}
                    </th>
                    <td>{row.Author}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query BooksQuery {
    allGbvBooksCatalogCsv {
      nodes {
        items {
          Author
          Code
          No
          Title
          Volume
        }
        id
      }
    }
  }
`;

export default Books;
